import React from "react";
import styled from "styled-components";

const Btn = styled.a`
  display: inline-block;
  background-color: white;
  color: black;
  outline: none;
  border: none;
  font-weight: 600;
  font-size: ${(props) => props.theme.fontsm};
  padding: 0.9rem 2.3rem;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.2s ease;
  position: relative;
  &:hover {
    transform: scale(0.9);
  }

  &::after {
    content: " ";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scaleX(0) scaleY(0);
    border: 2px solid skyblue;
    background-color: rgba(135, 206, 235,0.1);
    width: 100%;
    height: 100%;
    border-radius: 80px;
    transition: all 0.2s ease;
  }

  &:hover::after {
    transform: translate(-50%, -50%) scaleX(1.1) scaleY(1.3);
    border-radius: 80px;
    padding: 0.3rem;
  }

`;

const Button = ({ text, link="#", newTab = false }) => {
  return (
    <Btn
      href={link}
      aria-label={text}
      target={newTab ? "_blank" : "_self"}
      rel="noreferrer"
    >
      {text}
    </Btn>
  );
};

export default Button;
