import React, { useState } from "react";
import styled from "styled-components";
import Logo from "./Logo";
import resume from "../assets/harsharesume.pdf";
import Marquee from "react-fast-marquee";
import '../index.css';

const Section = styled.section`
  width: 100vw;
  background-color: black;
`;

const Btn = styled.a`
  display: inline-block;
  background-color: white;
  color: black;
  outline: none;
  border: none;

  font-size: ${(props) => props.theme.fontsm};
  padding: 0.9rem 2.3rem;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.2s ease;
  position: relative;
  &:hover {
    transform: scale(0.9);
  }

  &::after {
    content: " ";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scaleX(0) scaleY(0);
    border: 2px solid skyblue;
    background-color: rgba(135, 206, 235,0.1);
    width: 100%;
    height: 100%;
    border-radius: 50px;
    transition: all 0.2s ease;
  }

  &:hover::after {
    transform: translate(-50%, -50%) scaleX(1.1) scaleY(1.3);
    border-radius: 80px;
    padding: 0.3rem;
  }

  @media (max-width: 64em) {
    font-size: ${(props) => props.theme.fontsm};
    margin-top: 5px;
    padding: 0.8rem 1.1rem;
  }
`;

const NavBar = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 85%;
  height: ${(props) => props.theme.navHeight};
  margin: 0 auto;

  .mobile {
    display: none;
  }

  @media (max-width: 64em) {
    
    .desktop {
      display: none;
    }
    .mobile {
      display: inline-block;
    }
  }
`;
const Menu = styled.ul`
  display: flex;
  justify-content: space-between;
  align-items: center;
  list-style: none;
  margin-left: 50px;
  margin-top: 15px;

  @media (max-width: 64em) {
    /* 1024 px */

    position: fixed;
    top: ${(props) => props.theme.navHeight};
    left: 0;
    right: 0;
    bottom: 0;
    margin-left: 0px;
    margin-top: 0px;
    width: 100vw;
    height: ${(props) => `calc(100vh - ${props.theme.navHeight})`};
    z-index: 50;
    background-color: rgba(0, 0, 0, 0.7);
    backdrop-filter: blur(25px);

    transform: ${(props) =>
      props.click ? "translateY(0)" : `translateY(1000%)`};
    transition: all 0.3s ease;
    flex-direction: column;
    justify-content: center;

    touch-action: none;
  }
`;

const MenuItem = styled.li`
  margin: 0 1rem;
  color: white;
  text-align: center;
  cursor: pointer;
  padding-bottom: 1em;
  width: 100%;

  &::after {
    background-color: skyblue;
    content: " ";
    display: block;
    width: 0%;
    height: 2px;
    transition: width 0.3s ease;
  }
  &:hover::after {
    width: 100%;
  }

  @media (max-width: 64em) {
    margin: 1rem 0;

    &::after {
      display: none;
    }
  }
`;
const HamburgerMenu = styled.span`
  width: ${(props) => (props.click ? "2rem" : "1.5rem")};

  height: 2px;
  background: white;

  position: absolute;
  top: 2.5rem;
  left: 50%;
  transform: ${(props) =>
    props.click
      ? "translateX(-50%) rotate(90deg)"
      : "translateX(-50%) rotate(0)"};

  display: none;
  justify-content: center;
  align-items: center;

  cursor: pointer;
  transition: all 0.3s ease;

  @media (max-width: 64em) {
    /* 1024 px */
    display: flex;
  }

  &::after,
  &::before {
    content: " ";
    width: ${(props) => (props.click ? "1rem" : "1.5rem")};
    height: 2px;
    right: ${(props) => (props.click ? "-2px" : "0")};
    background: white;
    position: absolute;
    transition: all 0.3s ease;
  }

  &::after {
    top: ${(props) => (props.click ? "0.3rem" : "0.5rem")};
    transform: ${(props) => (props.click ? "rotate(-40deg)" : "rotate(0)")};
  }
  &::before {
    bottom: ${(props) => (props.click ? "0.3rem" : "0.5rem")};
    transform: ${(props) => (props.click ? "rotate(40deg)" : "rotate(0)")};
  }
`;


const Navigation = () => {
  const [click, setClick] = useState(false);

  const scrollTo = (id) => {
    let element = document.getElementById(id);

    element.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
    

    setClick(!click);
  };
  
  return (
    <Section id="navigation" >
      <NavBar>
        <Logo />
        <HamburgerMenu class="Hmenu" click={+click} onClick={() => setClick(!click)}>
          &nbsp;
        </HamburgerMenu>
        <Menu click={+click}>
          <MenuItem onClick={() => scrollTo("home")}>Home</MenuItem>
          <MenuItem onClick={() => scrollTo("about")}>Skills</MenuItem>
          <MenuItem onClick={() => scrollTo("roadmap")}>Education</MenuItem>
          <MenuItem onClick={() => scrollTo("projects")}>Projects</MenuItem>
          <MenuItem onClick={() => scrollTo("faq")}>Learnings</MenuItem>
          <MenuItem onClick={() => scrollTo("certificates")}>Certificates</MenuItem>
          <MenuItem>
            <div className="mobile">
            <Btn
            href="https://www.linkedin.com/in/harsha-vardhan-majji-45b51b230/"
            aria-label="Connect Me"
            target="_blank"
            rel="noreferrer"
            >
            Connect Me
            </Btn>
            </div>
          </MenuItem>
        </Menu>
        <Btn
                href={resume}
                aria-label="Resume"
                target="_blank"
                rel="noreferrer"
              >
                Resume
            </Btn>
        <div className="desktop">
        </div>
      </NavBar>
    <div className="Mar">
      <Marquee className="flex items-center justify-center bg-gradient-to-r from-cyan-500 to-blue-500 text-white font-bold">&#9733; EXPLORE  IN  DESKTOP  FOR  3D  &#9733;</Marquee>
    </div>
    </Section>
  );
};

export default Navigation;









