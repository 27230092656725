 export const projects=[
    {
      "status": "ok",
      "totalResults": 11,
      "datap": [
        {
          "source": {
            "id": 1,
          },
          "name": "Foodie",
          "url": "https://res.cloudinary.com/dukyqmnad/video/upload/v1709737244/pro/foodie_ot7c6y.mp4",
          "description": `
          In this latest endeavor, I present the "Foodie Food Ordering App," a project developed using React.js and Tailwind CSS. This venture has been a significant learning experience, allowing me to refine my Tailwind CSS skills and elevate my proficiency in React state management and advanced props handling.

Key Features of the Foodie App include a delightful Foodie-themed interface, complete with a user-friendly search bar, shopping cart, and a streamlined purchase process featuring various payment options. A notable feature is the visual display of the payment card during entry, complete with logos, providing a dynamic and engaging user experience.

The app is poised for seamless integration with a payment gateway, despite minor deployment restrictions. Users can explore an organized menu with various categories, simplifying the process of discovering their favorite dishes. The gradient sidebar not only adds aesthetic appeal but also enhances user experience, facilitating easy navigation across all pages.

Hovering animations introduce an element of interactivity and playfulness, contributing to an engaging overall experience. The warm and inviting orange color palette, carefully designed to complement the Foodie theme, creates a visually appealing atmosphere.

Your support and feedback are invaluable. Let's connect on LinkedIn to delve into discussions about this project and explore potential collaborations. Thank you for being an integral part of this journey!
          `,
          "visit": "https://foodie-harsha.netlify.app",
          "github": "https://github.com/harsha42703/foodie",
          "tech": [
            "React js",
            "Tailwind css",
            "HTML 5",
            "JSON"
          ]
        },
        {
          "source": {
            "id": 2,
          },
          "name": "Nuemorphic Calculator",
          "url": "https://res.cloudinary.com/dukyqmnad/video/upload/v1709737239/pro/Video_2024_03_06_14_28_58_ywk0xa.mp4",
          "description": `Feel free to explore the various color themes of my latest project, the Neumorphic Calculator, and share your thoughts! I'm excited to present a calculator that seamlessly combines functionality with aesthetics. The calculator boasts a sleek and modern UI, following the neumorphic design trend to provide users with an intuitive and visually pleasing experience. Users can effortlessly switch between various color themes, allowing for personalization and customization according to individual preferences. The calculator ensures a fully responsive experience on all devices, maintaining seamless functionality from desktop to mobile.

          In terms of technologies, HTML forms the foundation for the project, with CSS handling styling to achieve the neumorphic look. JavaScript empowers the calculator with dynamic functionality and accurate calculations. The choice of neumorphic design is intentional, as it combines minimalism and realism, creating a distinctive interface while maintaining user-friendly simplicity. This design philosophy enhances user engagement, contributing to an improved overall user experience.
          
          Beyond its aesthetic appeal, the Neumorphic Calculator is a fully functional tool for performing quick and accurate calculations. For those interested in staying updated on my latest projects and insights in web development, connecting with me on LinkedIn is encouraged. Together, let's shape the future of web design!`,
          "visit": "https://neumorphic-interface-calculator.netlify.app",
          "github": "https://github.com/harsha42703/Neumorphism-Calculator",
          "tech": [
            "React js",
            "Javascript",
            "CSS",
            "HTML 5"
          ]
        },
        {
          "source": {
            "id": 3,
          },
          "name": "CodeCraft Pro",
          "url": "https://res.cloudinary.com/dukyqmnad/video/upload/v1709737235/pro/codeorg_mwcrou.mp4",
          "description": `Introducing CodeCraftPro, an innovative coding editor that eliminates the hassle of managing style sheets and scripts manually. Say goodbye to tedious dependencies and welcome a streamlined coding experience where you can focus on crafting exceptional websites without the complexities of manual linking.

          Unlock a world of creativity with CodeCraftPro's extensive collection of online fonts, providing you with a diverse range to enhance your designs and give your web projects a unique personality. Enjoy a seamless coding experience on any device, as our editor is designed to be responsive and user-friendly across desktops, tablets, and smartphones.
          
          CodeCraftPro brings together HTML, CSS, and JavaScript editing in one powerful platform, eliminating the need to juggle multiple applications. This integration streamlines your workflow, making coding more efficient and accessible. Witness your changes in real-time with the live preview feature, eliminating the need to save and refresh. See your project come to life as you code, providing instant gratification and enhancing overall efficiency.
          
          Concerned about losing your work? CodeCraftPro has you covered with its auto-save feature that securely stores your projects in local storage. Developed using React.js, CodeCraftPro stands out as a robust and reliable tool, ensuring a stable and efficient coding experience. Experience the future of web development with CodeCraftPro, where coding becomes an art, and your creativity knows no bounds! #WebDevelopment #CodeCrafting #EfficiencyUnleashed`,
          "visit": "https://code-craft-pro.netlify.app",
          "github": "https://github.com/harsha42703/codecraftpro",
          "tech": [
            "React js",
            "Javascript",
            "CSS",
            "HTML 5",
          ]
        },
        {
          "source": {
            "id": 4,
          },
          "name": "YourNEWS",
          "url": "https://res.cloudinary.com/dukyqmnad/video/upload/v1709737243/pro/news_u9h5zd.mp4",
          "description": `Introducing YourNews, a news app designed to be more than just a utilitarian tool—it offers an immersive experience through a captivating aqua interface and a sleek dark theme that seamlessly adapts to various screen sizes. Built with a focus on delivering a smooth user experience, the application features captivating animations throughout. Noteworthy elements include multi-gradient cards with hover animations on buttons, adding an elegant touch to the news browsing interface. Uniquely, YourNews intelligently filters news cards based on image presence, ensuring a visually engaging experience by fetching only articles with images from the API.

          The app's aesthetic design incorporates a smoky navbar UI and eye-catching glowing smoky buttons, enhancing its overall visual appeal. For added user comfort, YourNews includes a dark mode toggle, catering to late-night readers. The live demo is powered by dummy data fetched from the developer's server, "news-api-harsha," providing a firsthand look at the app's functionality. Technically, YourNews is developed with the MERN stack, combining modern technologies for a dynamic web application. The CSS implementation incorporates Tailwind and CSS, ensuring a clean and responsive design. This project showcases a fusion of cutting-edge technologies, thoughtful design, and a touch of magic, reflecting the developer's passion for web development and UI/UX design. Explore the live demo to experience the future of news browsing and share your valuable feedback. 📰👁️💬`,
          "visit": "https://your-news-harsha.netlify.app",
          "github": "https://github.com/harsha42703/yournews",
          "tech": [
            "MERN stack",
            "Tailwind css",
            "HTML 5"
          ]
        },
        {
          "source": {
            "id": 5,
          },
          "border": "border-8 md:border-[20px]",
          "name": "Quote Picker",
          "url": "https://res.cloudinary.com/dukyqmnad/video/upload/v1709741530/pro/quote_xq6d3w.mp4",
          "description": `Excited to revisit my inaugural React project – the Quote Picker! 🚀✨ This project holds a special place as it marked the commencement of my React journey, brimming with features that set it apart. The Quote Picker boasts smooth gradients, elevating its visual appeal and providing users with an aesthetically pleasing experience.

          Ensuring accessibility, the project is designed with a responsive layout, guaranteeing seamless browsing across any device. The addition of a Copy button functionality simplifies the sharing of favorite quotes, adding a practical touch to the user experience.
          
          Immersive hover animations bring quotes to life, adding a dynamic element to the project. Meanwhile, transition animations contribute an extra layer of elegance, enhancing the overall visual appeal.
          
          The Quote Picker serves as a delightful throwback to the early days of my React journey, encapsulating the essence of those coding memories. I invite you to check it out and relive the excitement with me! 🌈`,
          "visit": "https://quote-picker-harsha.netlify.app",
          "github": "https://github.com/harsha42703/QUOTE_PICKER",
          "tech": [
            "React js",
            "Javascript",
            "CSS",
            "HTML 5"
          ]
        },
        {
          "source": {
            "id": 6,
          },
          "border": "border-8 md:border-[20px]",
          "name": "ShopHere",
          "url": "https://res.cloudinary.com/dukyqmnad/video/upload/v1709741530/pro/admin_pzijbt.mp4",
          "description": `Thrilled to unveil the Shophere Admin Dashboard project, a culmination of dedicated effort and innovation! 🚀📊 This dynamic dashboard is equipped with a range of exciting features designed to enhance your data management experience. Engage with your data like never before as dynamic motion animations bring key metrics, including reviews, stock, revenue, sales, and expenses, to life in an insightful visual display.

          The responsive design ensures a seamless experience across all screen sizes, while a glassy transparency effect adds a touch of sleek modernity to the UI. Explore data effortlessly with a versatile analytics menu that allows you to navigate through various dimensions, gaining valuable insights.
          
          In terms of data flexibility, Shophere Admin Dashboard offers an efficient solution for downloading data in different formats. Tailor your graphs with ease using the provided filtering options, adapting them to various parameters for a personalized analytical experience.
          
          This project is meticulously crafted with React and powered by the React Apex Charts package, guaranteeing stunning and intuitive data visualization. I invite you to explore the live demo and share your thoughts on this exciting venture!`,
          "visit": "https://shophere-harsha.netlify.app",
          "github": "https://github.com/harsha42703/adminboard",
          "tech": [
            "React js",
            "React Apex Charts",
            "Javascript",
            "Styled-Components",
            "HTML 5"
          ]
        },
        {
          "source": {
            "id": 7,
          },
          "border": "border-8 md:border-[20px]",
          "dolhi": "md:h-[77vh]",
          "name": "Dolphin",
          "url": "https://res.cloudinary.com/dukyqmnad/video/upload/v1709778258/pro/dolphin_pwqumw.mp4",
          "description": `I'm thrilled to introduce my latest project, "Dolphin - Restaurant Management System," aimed at revolutionizing the hotel dining experience. Beyond conventional table reservations, Dolphin offers a comprehensive solution catering to various facets of hotel-based dining, including private events, parties, and traditional dining setups.

          Throughout the development phase, I dedicated considerable attention to state management intricacies and leveraged the React Material-UI framework, ensuring the creation of a robust and user-friendly application. Dolphin stands out for its commitment to delivering a seamless, efficient, and immersive experience for both guests and restaurant staff. The interface has been meticulously refined, featuring a thoughtfully designed aqua theme that imparts a fresh and inviting ambiance.
          
          What truly distinguishes Dolphin is its capability to provide a holistic and efficient experience for guests and restaurant staff alike. The aqua-themed interface enhances the overall aesthetic, creating an inviting atmosphere for users. I invite you to explore our demo for an in-depth look at Dolphin's complete feature set. Witness firsthand how this system elevates the hotel dining experience to new heights.`,
          "visit": "https://dolphin-rms.netlify.app",
          "github": "https://github.com/harsha42703/Dolphin",
          "tech": [
            "React js",
            "Javascript",
            "SASS/SCSS",
            "HTML 5"
          ]
        },
        {
          "source": {
            "id": 8,
          },
          "name": "Private Meet",
          "url": "https://res.cloudinary.com/dukyqmnad/video/upload/v1708479781/pro/privatemeet.mp4",
          "description": `PrivateMeet, a state-of-the-art online communication platform, introduces the groundbreaking concept of "Incognito Video Calls." Developed with the MERN stack, encompassing MongoDB, Express.js, React, and Node.js, the platform establishes a secure and reliable foundation. Prioritizing user security, PrivateMeet implements end-to-end encryption for video calls and private chats. The integration of the WebRTC framework facilitates seamless peer-to-peer connections, enhancing the overall communication experience.

          This platform boasts a responsive design, ensuring seamless adaptation to various screens and optimizing the communication experience across devices. PrivateMeet's distinctive blue gradients in the UI/UX create a calming ambiance, fostering an incognito atmosphere that aligns with professionalism. Beyond standard security measures, the platform incorporates advanced features to consistently safeguard user data throughout incognito video calls.
          
          WebRTC security measures further contribute to the incognito excellence in video calls. Users can experience unparalleled confidentiality with the "Incognito Video Calls" feature, enabling communication without leaving a trace. Peer-to-peer connections established through WebRTC ensure that video calls remain private and secure, eliminating intermediaries that may compromise user privacy. The platform employs robust encryption protocols aligned with WebRTC standards, guaranteeing that all communications are shielded from unauthorized access.
          
          PrivateMeet sets a new standard for confidential online communication by combining innovative features, secure technologies, and an elegant user interface. This comprehensive approach ensures a seamless and private user experience, redefining the landscape of online communication.`,
          "visit": "https://privatemeet.vercel.app/",
          "github": "https://github.com/harsha42703/privatemeet",
          "tech": [
            "MERN stack",
            "Material-UI",
            "WEB_RTC"
          ]
        },
        {
          "source": {
            "id": 9,
          },
          "name": "Snapie",
          "url": "https://res.cloudinary.com/dukyqmnad/video/upload/v1708479091/pro/snapie.mp4",
          "description": `Snapie, a social media platform meticulously crafted with precision, boasts a tech stack comprising React, Appwrite, and TypeScript. The backend revolution is fueled by the beta version of Appwrite, pushing the boundaries of backend capabilities. Scripting mastery is evident with the seamless implementation of TypeScript, enhancing efficiency in every aspect.

          The design elegance of Snapie is achieved through the fusion of ShadcnUI and Tailwind CSS, ensuring a visually appealing user interface. Zod takes charge of form validations, ensuring data integrity and application robustness. The asynchronous state management by Tanstack Query optimizes performance, contributing to a seamless and responsive user experience.
          
          Unleashing a new era in social media interaction, Snapie leverages Appwrite's novel features for revolutionary backend functionality. The brilliance of Appwrite's schema enhances database skills, elevating the application's sophistication. The UI/UX excellence is characterized by a uniform black palette and vibrant purple hue, creating an engaging atmosphere.
          
          Snapie's feature set covers every aspect of social media engagement. Users can seamlessly create, upload, and interact with posts, covering all aspects of post control. Personalized profiles offer an aesthetically pleasing experience with saved and liked posts filtering. The Explore page brings magic to discovering related posts with a personalized and curated experience, while the flawless newsfeed showcases all posts with optimal resolution and responsiveness for screens up to 280px width.
          
          Functionality-wise, Snapie's capabilities include post creation with hashtags, locations, and captions, fostering community engagement through likes and saved posts. User-friendly tabs facilitate seamless navigation, and the Explore and Discover feature ensures a personalized experience in finding relevant and intriguing posts. Easy filtering of saved and liked posts on user profiles and a flawless newsfeed further contribute to the platform's excellence.`,
          "visit": "https://snapie.vercel.app/",
          "github": "https://github.com/harsha42703/snapie",
          "tech": [
            "Typescript",
            "React js",
            "Appwrite.io",
            "Zod Validation",
            "Tanstack Query",
            "React Query",
            "Shadcn-UI",
          ]
        },
        {
          "source": {
            "id": 10,
          },
          "name": "Stock Manager",
          "url": "https://res.cloudinary.com/dukyqmnad/video/upload/v1709737238/pro/stocklap_aq5pvm.mp4",
          "description": `Introducing Stock Manager by GraphQL, a revolutionary advancement in stock data management. The platform, now live and accessible [here](https://lnkd.in/gXheu7Kh), leverages the cutting-edge capabilities of GraphQL for a seamless and efficient backend experience. React and Tailwind CSS collaborate to deliver a visually stunning and user-friendly interface that adapts seamlessly to any device.

          Stock Manager offers an interface designed for maximum user convenience, featuring effortless stock data handling through streamlined upload and download functionalities. Users can also enjoy enhanced flexibility with the ability to edit and delete data entries. The platform's responsive design ensures a superior user experience across various devices, catering to the diverse needs of stock management.
          
          Security is at the core of Stock Manager, with authentication powered by Firebase. Whether through email authentication or the convenience of Google auth, users can trust that their data is in safe hands. The platform goes the extra mile with state-of-the-art authentication, incorporating Redux for state management and implementing protected routes to ensure that not only is your data effectively managed but also meticulously safeguarded.
          
          Join us in shaping the future of stock data management with Stock Manager. Explore today and experience the seamless integration of GraphQL, React, Tailwind CSS, Firebase, and cutting-edge technologies that define the future of stock data management. #StockManager #GraphQL #React #TailwindCSS #Firebase #FutureTech 📈💻`,
          "visit": "https://stock-manager-graphql.vercel.app",
          "github": "https://github.com/harsha42703/STOCK_MANAGER_GRAPHQL",
          "tech": [
            "Apollo GraphQL",
            "React js",
            "Firebase Authentication",
            "Tailwind css",
            "MongoDB",
            "Redux State Management",
          ]
        },
        {
          "source": {
            "id": 11,
          },
          "border": "border-8 md:border-[20px]",
          "name": "Stepcone",
          "url": "https://res.cloudinary.com/dukyqmnad/video/upload/v1709741531/pro/video_cqyz1d.mp4",
          "description": `The GMRIT Stepcone 2024 Official Website, a collaborative effort led by me as the lead full-stack developer, introduces a seamless event management experience. The platform boasts an Easy Registration feature, streamlining participant sign-ups for Stepcone with a user-friendly process. Events are intelligently categorized, each having its dedicated page, enhancing accessibility for participants to explore and engage. The website's User-Friendly Interface ensures smooth navigation, offering a friendly and intuitive experience for users.

          Behind the scenes, the Admin Page provides administrators with specialized tools, simplifying the management of event registrations and user data. Leveraging technologies such as HTML, CSS, Bootstrap, and JavaScript, I contributed to crafting the website's appealing aesthetics and responsive design. The technical backbone, powered by PHP and MySQL, ensures robust functionality and efficient data management.
          
          Mobile-Friendly functionality is a key aspect, allowing participants to access and engage with the website seamlessly on their mobile devices. Our vision for this project is to elevate the event management experience for both participants and organizers of Stepcone 2024. By blending technology seamlessly, we aim to create an unforgettable and efficient platform that aligns perfectly with the unique needs of our college event.`,
          "visit": "https://gmrit.edu.in/stepcone/",
          "github": "https://github.com/harsha42703/STEPCONE",
          "tech": [
            "Php",
            "MySql",
            "Bootstrap",
            "Css",
            "HTML 5",
            "Javascript",
          ]
        },
        {
          "source": {
            "id": 12,
          },
          "name": "Authentication",
          "url": "https://res.cloudinary.com/dukyqmnad/video/upload/v1709746514/pro/auth_wm19jo.mp4",
          "description": `Immerse yourself in a visually appealing and smoothly animated authentication experience with our Login & Registration project. The elegant design, empowered by the Poppins font and Boxicons library, welcomes users with a captivating background image. The interface seamlessly transitions between the login and registration forms, providing an intuitive and engaging user journey.

          The login mechanism employs local storage for secure user authentication. Upon successful login, a delightful success message appears, assuring users of a successful login. The registration process is equally enchanting, featuring a dynamic form with real-time image preview functionality. Users are greeted with informative notifications, such as a confirmation of successful registration or alerts for existing email addresses.
          
          The meticulous attention to detail extends to the use of gradients and eye-catching colors, creating a visually stunning presentation. The project ensures a memorable experience, combining functionality with a user-friendly interface. Explore the future of authentication seamlessly with our Login & Registration project.`,
          "visit": "https://harsha42703.github.io/Authentication/",
          "github": "https://github.com/harsha42703/Authentication",
          "tech": [
            "Javascript",
            "HTML 5",
            "css"
          ]
        },
        {
          "source": {
            "id": 13,
          },
          "name": "Question Paper Generator",
          "url": "https://res.cloudinary.com/dukyqmnad/video/upload/v1717164343/pro/Qpg.mp4",
          "description": `

          our latest mini project, the Question Paper Generator! Developed by Harsha Vardhan Majji, Lakshmi Lavanya, and Mahanthi Vikas, this application aims to streamline the process of creating question papers for educational institutions, significantly reducing the time required for this task. The key features of our application include authentication & authorization, bulk uploading of questions in Excel format, management of branches, semesters, subjects, and questions (add, edit, delete), segregation of questions by branch, semester, subject, and marks, uploading images for questions, question paper template creation, generation of question papers, and exporting of question papers. This versatile application, built using the LAMP stack, can be easily adapted to meet specific requirements, making it a valuable tool for educational institutions. Thank you for reading, and feel free to reach out for more details or collaboration opportunities!`,
          "hide":"hidden",
          "github": "https://github.com/harsha42703/QUESTION-PAPER-GENERATOR",
          "tech": [
            "MYSQL",
            "PHP",
            "BOOTSTRAP",
            "JAVASCRIPT",
          ]
        }
      ]
    }
  ]


export const certs =[
  {
    "status": "ok",
    "totalResults": 11,
    "datac": [
      {
        "source": {
          "id": null,
          "name": "AWS"
        },
        "url": "https://res.cloudinary.com/dukyqmnad/image/upload/v1708477922/cer/aws.png",
        "description": "AWS Foundation",
        "verify": "https://www.credly.com/go/qaBYScvn"
      },
      {
        "source": {
          "id": null,
          "name": "NPTEL"
        },
        "url": "https://res.cloudinary.com/dukyqmnad/image/upload/v1708477922/cer/nptel.png",
        "description": "Cloud Computing NPTEL, By IIT - Kharagpur",
        "verify": "https://internalapp.nptel.ac.in/NOC/NOC23/SEM2/Ecertificates/106/noc23-cs89/Course/NPTEL23CS89S54760021120183620.pdf"
      },
      {
        "source": {
          "id": null,
          "name": "NPTEL"
        },
        "url": "https://res.cloudinary.com/dukyqmnad/image/upload/v1708477922/cer/nptel2.png",
        "description": "Bussiness Intelligence and Analytics",
        "verify": "https://internalapp.nptel.ac.in/NOC/NOC23/SEM2/Ecertificates/106/noc23-cs89/Course/NPTEL23CS89S54760021120183620.pdf"
      },
      {
        "source": {
          "id": null,
          "name": "Edx"
        },
        "url": "https://res.cloudinary.com/dukyqmnad/image/upload/v1708477922/cer/edx.png",
        "description": "Machine Learning - IBM",
        "verify": "https://courses.edx.org/certificates/400a430e36194605877feaa42b9785b2?_gl=1*q3qtqj*_ga*MTcxODgxODkyLjE3MTcxNjQ3MTg.*_ga_D3KS4KMDT0*MTcxNzE2NDc0OC4xLjAuMTcxNzE2NDc0OC42MC4wLjA."
      },
      {
        "source": {
          "id": null,
          "name": "Google"
        },
        "url": "https://res.cloudinary.com/dukyqmnad/image/upload/v1708477921/cer/da.png",
        "description": "Google Data Analytics",
        "verify": "https://coursera.org/verify/YPWZ459W8DM5"
      },
      {
        "source": {
          "id": null,
          "name": "IBM"
        },
        "url": "https://res.cloudinary.com/dukyqmnad/image/upload/v1708477923/cer/react.png",
        "description": "Front-end apps with React",
        "verify": "https://coursera.org/verify/9KBM2JQPDQ33"
      },
      {
        "source": {
          "id": null,
          "name": "IBM"
        },
        "url": "https://res.cloudinary.com/dukyqmnad/image/upload/v1708477924/cer/ds.png",
        "description": "Python for Data Scientist, AI & Dev.",
        "verify": "https://coursera.org/verify/5EPJ83C8P7JN"
      },
      {
        "source": {
          "id": null,
          "name": "Meta"
        },
        "url": "https://res.cloudinary.com/dukyqmnad/image/upload/v1708477923/cer/py.png",
        "description": "Python Programming by Meta",
        "verify": "https://coursera.org/verify/LRKGDQDBWNKC"
      },
      {
        "source": {
          "id": null,
          "name": "Meta"
        },
        "url": "https://res.cloudinary.com/dukyqmnad/image/upload/v1708477923/cer/front.png",
        "description": "Web Development by Meta",
        "verify": "https://coursera.org/verify/XMYGXPAPHZXP"
      },
      {
        "source": {
          "id": null,
          "name": "IJRPR"
        },
        "url": "https://res.cloudinary.com/dukyqmnad/image/upload/v1708477922/cer/pub.png",
        "description": "Video Frame Regeneration",
        "verify": "demo link"
      },
      {
        "source": {
          "id": null,
          "name": "Freedom with AI"
        },
        "url": "https://res.cloudinary.com/dukyqmnad/image/upload/v1708477922/cer/ai.png",
        "description": "AI Masterclass",
        "verify": "demo link"
      },
    ]
  }
]