"use client";
import  TextGenerateEffect  from "../components/TextGenerateEffect";

const words = `As a seasoned creative professional, my journey in the realms of multimedia and technology has been a dynamic fusion of passion and expertise. With a profound commitment to delivering excellence, I've cultivated a diverse skill set that encompasses photography, video editing, audio equalization, 3D web development, UI/UX design, graphic design, content writing, programming, reasoning, and aptitude skills. Here, I present a brief overview of my proficiency in each area, demonstrating my unwavering dedication to professionalism and classic craftsmanship.
`;

function Txtdemo() {
  return <TextGenerateEffect words={words} />;
}
export default Txtdemo;
