import React,{useLayoutEffect} from "react";
import styled from "styled-components";
import Typewriter from "typewriter-effect";
import Button from './Button';
import '@fontsource/londrina-outline';
import 'aos/dist/aos.css';
import Aos from 'aos';


const Title = styled.h2`
  font-family: poppins;
  font-weight: 700;
  font-size: ${(props) => props.theme.fontxxl};
  letter-spacing: 2px;
  text-transform: capitalize;
  width: 80%;
  align-self: flex-start;
  color: black;
  font-weight: 600;
  -webkit-text-stroke: 1px white;

  span {
    text-transform: uppercase;
    font-family: "poppins", sans-serif;
    font-weight: 600;
    letter-spacing: 5px;
    color: black;
    line-height: 5px;
    
  }
  .name{
    color: rgba(255, 255, 255,0.5);
    font-size: 64px;
    -webkit-text-stroke: 0px white;
    font-weight: 600;
    @media (max-width: 48em) {
      font-size: 36px;
      color: rgba(255, 255, 255,1);
    }
  }

  .text-1{
   -webkit-text-stroke: 0px skyblue;
      background: -webkit-linear-gradient(45deg,#05e1fa, #5bacfc);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent; 
  }
  .text-2{
   -webkit-text-stroke: 0px skyblue;
      background: -webkit-linear-gradient(45deg,#05e1fa, #5bacfc);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent; 
  }
  .text-3{
   -webkit-text-stroke: 0px skyblue;
      background: -webkit-linear-gradient(45deg,#05e1fa, #5bacfc);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent; 
  }
  .text-4{
   -webkit-text-stroke: 0px skyblue;
      background: -webkit-linear-gradient(45deg,#05e1fa, #5bacfc);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent; 
  }
  

  @media (max-width: 70em) {
    font-size: ${(props) => props.theme.fontxl};

  }
  @media (max-width: 48em) { 
    align-self: center;
    text-align:center;
  }
  @media (max-width: 40em){
    width: 90%;
  }  
`;


const SubTitle = styled.h3`
  font-size: ${(props) => props.theme.fontlg};
  text-transform: capitalize;
  color: #fff;
  text-shadow: 2px 2px 3px black;
  font-weight:600;
  margin-bottom: 1rem;
  width: 80%;
  align-self: flex-start;

  @media (max-width: 40em) {
    font-size: ${(props) => props.theme.fontmd};
    color: rgba(255,255,255,0.9);
  }

  @media (max-width: 48em) { 
    align-self: center;
    text-align:center;
  }
  
`

const ButtonContainer = styled.div`
 width: 80%;
  align-self: flex-start;

  @media (max-width: 48em) { 
    align-self: center;
    text-align:center;

    button{
      margin: 0 auto;
    }
  }

`
const TypeWriterText = () => {

  useLayoutEffect(() => {
    Aos.init({duration:1000})
    return () => {
    };
  }, [])

  return (
    <>
        <Title data-Aos="fade-right" >
      this is<br/>
      <span class="name" >Harsha Vardhan</span>
      <Typewriter
        data-Aos="fade-right"
        options={{
          autoStart: true,
          loop: true,
        }}
        onInit={(typewriter) => {
          typewriter
            .typeString(`<span class="text-1">UI/UX DESGINER</span>`)
            .pauseFor(2000)
            .deleteAll()
            .typeString(`<span class="text-2">3D FRONTEND DEVELOPER</span>`)
            .pauseFor(2000)
            .deleteAll()
            .typeString(`<span class="text-3">BACKEND DEVELOPER</span>`)
            .pauseFor(2000)
            .deleteAll()
            .typeString(`<span class="text-4">PROGRAMMER</span>`)
            .pauseFor(2000)
            .deleteAll()
            .start();
        }}
      />
      
    </Title>
    <SubTitle>Connect Me On LinkedIn...</SubTitle>
    <ButtonContainer>
    <Button text="Explore" link="#about" />
    </ButtonContainer>
    </>
  );
};

export default TypeWriterText;