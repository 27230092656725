import React,{useLayoutEffect} from 'react'
import styled from 'styled-components'
import harsha from '../assets/me.jpg'
import 'aos/dist/aos.css';
import Aos from 'aos';


const VideoContainer = styled.div`
width: 100%;
display: flex;
justify-content: center;
align-items: center;

img{
    width: 80%;
    height: auto;
    border-radius: 50%;
}

@media (max-width: 64em) {
  min-width: 40vh;
}
`

const CoverVideo = () => {
  useLayoutEffect(() => {
    Aos.init({duration:1000})
    return () => {
    };
  }, [])
  return (
    <VideoContainer data-Aos="fade-left">
        <img src={harsha} alt="img" />
    </VideoContainer>
  )
}

export default CoverVideo