import React from 'react';
import { useNavigate, Routes, Route } from 'react-router-dom';
import GlobalStyles from "./styles/GlobalStyles";
import { light } from "./styles/Themes";
import { ThemeProvider } from "styled-components";
import Navigation from "./components/Navigation";
import About from "./components/sections/About";
import Home from "./components/sections/Home";
import Roadmap from "./components/sections/Roadmap";
import Faq from "./components/sections/Faq";
import ProjectView from "./components/sections/ProjectView";
import Footer from "./components/Footer";
import ScrollToTop from "./components/ScrollToTop";
import ProjectPage from './components/sections/ProjectPage';
import CertificatePage from './components/sections/CertificatePage';

function App() {
  const navigate = useNavigate();

  const isProjectViewActive = /^\/project\/\d+$/.test(window.location.pathname);

  const handleRefresh = () => {
    navigate(window.location.pathname, { replace: true });
  };

  return (
    <div>
      <main>
        <GlobalStyles />
        <ThemeProvider theme={light}>
          {
            isProjectViewActive ? (
              <Routes>
                <Route path="/project/:id" element={<ProjectView />} />
              </Routes>
            ) : (
              <>
                <Navigation />
                <Home />
                <About />
                <Roadmap />
                <ProjectPage />
                <CertificatePage />
                <Faq />
                <Footer />
                <ScrollToTop />
              </>
            )
          }
        </ThemeProvider>
      </main>
    </div>
  );
}

export default App;
