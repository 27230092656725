import React,{useLayoutEffect} from 'react';
import styled, { keyframes } from 'styled-components';
import 'aos/dist/aos.css';
import Aos from 'aos';


const MarqueeContainer = styled.div`
  background: rgb(10,10,10);
  background: linear-gradient(90deg, rgba(10,10,10,0.0466780462184874) 0%, rgba(81,190,234,0.75704219187675066) 50%, rgba(0,0,0,0.0973783263305322) 100%);
  overflow: hidden;
  border-radius: 18px;
  // box-shadow: 0px 0px 50px 1px skyblue;
  
`;

const marqueeAnimation = keyframes`
  0% { transform: translateX(100%); }
  100% { transform: translateX(-100%); }
`;

const Marquee = styled.div`
  display: flex;
  animation: ${marqueeAnimation} 13s linear infinite;
  white-space: nowrap;

  &:hover {
    animation-play-state: paused;
  }
`;

const Logo = styled.img`
  width: auto;
  height: 50px;
  margin-right: 50px;
  transition: transform 0.3s ease;
  transform-origin:center center;
  border-radius: 13px;

  &:hover{
    transform: scale(1.2);
  }
`;

const MarqueeImage = ({images}) => {


  useLayoutEffect(() => {
    Aos.init({duration:1000})
    return () => {
    };
  }, [])

  return (
    <div>
    <br/>
    <MarqueeContainer data-Aos="fade-right">
      <Marquee>
        {
            images.map((imagelinks,index)=>(
                <Logo src={imagelinks} alt={`image ${index}`} key={index} data-Aos='fade-up'/>
            ))
        }
      </Marquee>
    </MarqueeContainer>
     <br/>
    <br/>
    </div> 
  );
};

export default MarqueeImage;
