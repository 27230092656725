"use client";

import React from "react";
import { CardBody, CardContainer, CardItem } from "./3d-card";





function Certificatecard({data}) {
  const { url, description,source,verify } = data;
  return (
            <CardContainer className="inter-var">
            <CardBody className=" relative group/card dark:hover:shadow-2xl dark:hover:shadow-emerald-500/[0.1] bg-black bg-opacity-50 dark:border-white/[0.2] border-black/[0.1] h-auto w-auto sm:w-[28rem] rounded-xl p-6 border">
              <CardItem
                translateZ="50"
                className="text-xl font-bold text-neutral-600 dark:text-white"
              >{source?.name}
              </CardItem>
              <hr />
              <div className="flex justify-between items-center mx-1 mt-2">
                <CardItem
                  as="p"
                  translateZ="60"
                  className="text-neutral-500 text-sm max-w-sm mt-2 dark:text-neutral-300"
                >
                  {description}
                </CardItem>
                <CardItem
                  translateZ={80}
                  as="a"
                  target="_blank"
                  href={verify}
                  className="px-4 py-2 rounded-xl bg-black dark:bg-white dark:text-black text-white text-xs font-bold group-hover/card:bg-sky-200 group-hover/card:text-sky-800"
                >
                  Verify
                </CardItem>
              </div>
              <CardItem translateZ="100" className="w-full mt-4">
                <img
                  src={url} 
                  height="1000"
                  width="1000"
                  className="h-60 w-full object-fill rounded-lg group-hover/card:shadow-xl group-hover/card:rounded-sm"
                  alt="thumbnail"
                />
              </CardItem>
            </CardBody>
          </CardContainer>
        );
}

export default Certificatecard;