import React from 'react';
import svg from '../assets/hvload.svg';



const Load = () => {
  return (
    <>
    <div className='bg-black flex w-screen h-screen justify-center items-center'>
        <img src={svg} alt="loading..."/>
    </div>
    </>
  )
}

export default Load;