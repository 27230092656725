import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import "@fontsource/lacquer"

const LogoText = styled.h1`
font-family: 'lacquer';
font-size: ${props => props.theme.fontxxl};
color: white;
font-weight: 700;
transition: all 0.2s ease;
span {
  color: skyblue;
}

&:hover{
    transform: scale(1.2);
}

@media (max-width: 64em){
font-size: ${props => props.theme.fontxxl};

}
`

const Logo = () => {
  return (
      <LogoText>
          <Link to="/">
          HV<span>.</span>
          </Link>
      </LogoText>
  )
}

export default Logo