import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Certificatecard from './Certificatecard';
import { certs } from '../db';

const ResponsiveBlock = styled.div`
  position: relative;
  width: 90vw;
  height: 68vh;
  margin: auto;
  border-radius: 18px;
  background-image: url("../assets/bg.png");
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 600px) {
    box-shadow: none;
    height: 70vh;
    overflow-x: scroll;
    flex-wrap: nowrap;
    width: 90vw;
    padding: 5px;
    gap: 5px;
    justify-content: flex-end;
    align-items: flex-end;
  }
`;

const CarouselContainer = styled.div`
  display: flex;
  gap: 30px;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  width: 100%;
  @media screen and (max-width: 600px){
    
  }
`;

const PaginationButton = styled.button`
  background-color: #CFEEFF;
  color: black;
  border: none;
  padding: 13px 30px;
  border-radius: 30px;
  cursor: pointer;
  &:hover {
    box-shadow: rgba(87, 127, 138, 0.9) 0px 0px 10px 2px;
  }
`;

const Box = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 100px;
  margin-top: 8px;
  @media screen and (max-width: 600px){
    margin-top: 0px;
    padding: 10px;
  }

`

const Filemove = () => {
  const [cert, setCert] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);

  async function getCertificates() {
    setCert(certs[0].datac);
    console.log(certs[0].datac);
  }

  useEffect(() => {
    getCertificates();
  }, []);

  const projects = cert.map((data, index) => (
    <Certificatecard key={index} data={data} />
  ));

  const handleNextPage = () => {
    if (currentPage < projects.length - 1) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <>
      <ResponsiveBlock>
        <CarouselContainer translateValue={-currentPage * (100 / projects.length)}>
          {projects.slice(currentPage, currentPage + 1)}
        </CarouselContainer>
      </ResponsiveBlock>
      <div className='h-[40px] w-screen'></div>
      <Box>
      <PaginationButton onClick={handlePrevPage} disabled={currentPage === 0}>
      <div className="flex flex-row justify-center items-center text-cyan-900 rotate-180 ">
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-right-fill" viewBox="0 0 16 16">
        <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z"/>
      </svg>
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-right-fill" viewBox="0 0 16 16">
        <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z"/>
      </svg>
     </div>
      </PaginationButton>
      <PaginationButton onClick={handleNextPage} disabled={currentPage >= projects.length - 1}>
     <div className="flex flex-row justify-center items-center text-cyan-900">
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-right-fill" viewBox="0 0 16 16">
        <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z"/>
      </svg>
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-right-fill" viewBox="0 0 16 16">
        <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z"/>
      </svg>
     </div>
      </PaginationButton>
      </Box>
    </>
  );
};

export default Filemove;
