import React,{useLayoutEffect} from 'react'
import '../index.css';
import 'aos/dist/aos.css';
import Aos from 'aos';
import {Link} from 'react-router-dom';
import qu from '../assets/qu.jpg'
import sn from '../assets/sn.jpg'
import cod from '../assets/cod.jpg'
import pri from '../assets/pri.jpg'
import cal from '../assets/cal.jpg'
import st from '../assets/st.jpg'
import fo from '../assets/fo.jpg'
import dol from '../assets/dol.jpg'
import adm from '../assets/adm.jpg'
import yo from '../assets/yo.jpg'
import ste from '../assets/Ste.jpg'
import aut from '../assets/aut.jpg'


const BentoGrid = () => {

  useLayoutEffect(() => {
    Aos.init({duration:500})
    return () => {
    };
  }, [])

  return (
    <div>
<div className="grid grid-cols-2 md:grid-cols-4 gap-4">
    <div className="grid gap-4">
            <div className="group relative">
            <div className="relative group" data-Aos="fade-right">
            <img
                className="h-auto max-w-full rounded-lg transition-all duration-300 group-hover:opacity-80"
                src={fo}
                alt=""
            />
            <div className="absolute inset-0 flex items-center justify-center opacity-0 bg-black bg-opacity-80 transition-opacity duration-300 group-hover:opacity-100">
                <Link to='/project/1' className="px-6 py-3 text-black font-bold border-2 border-orange-500 bg-white rounded-full">Visit Project</Link>
            </div>
            </div>
        </div>
        <div>
        <div className="relative group" data-Aos="fade-right">
        <img
            className="h-auto max-w-full rounded-lg transition-all duration-300 group-hover:opacity-80"
            src={adm}
            alt=""
        />
        <div className="absolute inset-0 flex items-center justify-center opacity-0 bg-black bg-opacity-80 transition-opacity duration-300 group-hover:opacity-100">
            <Link to='/project/6' className="px-6 py-3 text-white font-bold border-2 border-yellow-500 bg-black rounded-full">Visit Project</Link>
        </div>
        </div>
        </div>
        <div>
        <div className="relative group" data-Aos="fade-right">
        <img
            className="h-auto max-w-full rounded-lg transition-all duration-300 group-hover:opacity-80"
            src={cod}
            alt=""
        />
        <div className="absolute inset-0 flex items-center justify-center opacity-0 bg-black bg-opacity-80 transition-opacity duration-300 group-hover:opacity-100">
            <Link to='/project/3' className="px-6 py-3 text-white font-bold border-2 border-lime-500 bg-black rounded-full">Visit Project</Link>
        </div>
        </div>
        </div>
    </div>
    <div className="grid gap-4">
        <div>
        <div className="relative group" data-Aos="fade-up">
        <img
            className="h-auto max-w-full rounded-lg transition-all duration-300 group-hover:opacity-80"
            src={dol}
            alt=""
        />
        <div className="absolute inset-0 flex items-center justify-center opacity-0 bg-black bg-opacity-80 transition-opacity duration-300 group-hover:opacity-100">
            <Link to='/project/7' className="px-6 py-3 text-white font-bold border-2 border-blue-500 bg-black rounded-full">Visit Project</Link>
        </div>
        </div>
        </div>
        <div>
        <div className="relative group" data-Aos="fade-right">
        <img
            className="h-auto max-w-full rounded-lg transition-all duration-300 group-hover:opacity-80"
            src={pri}
            alt=""
        />
        <div className="absolute inset-0 flex items-center justify-center opacity-0 bg-black bg-opacity-80 transition-opacity duration-300 group-hover:opacity-100">
            <Link to='/project/8' className="px-6 py-3 text-white font-bold border-2 border-cyan-500 bg-black rounded-full">Visit Project</Link>
        </div>
        </div>
        </div>
        <div>
          <div className="relative group" data-Aos="fade-down">
            <img
                className="h-auto max-w-full rounded-lg transition-all duration-300 group-hover:opacity-80"
                src={cal}
                alt=""
            />
            <div className="absolute inset-0 flex items-center justify-center opacity-0 bg-black bg-opacity-80 transition-opacity duration-300 group-hover:opacity-100">
                <Link to='/project/2' className="px-6 py-3 text-white font-bold border-2 border-pink-500 bg-black rounded-full">Visit Project</Link>
            </div>
            </div>
        </div>
    </div>
    <div className="grid gap-4">
        <div>
        <div className="relative group" data-Aos="fade-down">
  <img
    className="h-auto max-w-full rounded-lg transition-all duration-300 group-hover:opacity-80"
    src={sn}
    alt=""
  />
  <div className="absolute inset-0 flex items-center justify-center opacity-0 bg-black bg-opacity-80 transition-opacity duration-300 group-hover:opacity-100">
    <Link to='/project/9'className="px-6 py-3 text-white font-bold border-2 border-purple-500 bg-black rounded-full">Visit Project</Link>
  </div>
</div>

        </div>
        <div>
        <div className="relative group" data-Aos="fade-left">
  <img
    className="h-auto max-w-full rounded-lg transition-all duration-300 group-hover:opacity-80"
    src={yo}
    alt=""
  />
  <div className="absolute inset-0 flex items-center justify-center opacity-0 bg-black bg-opacity-80 transition-opacity duration-300 group-hover:opacity-100">
    <Link to='/project/4' className="px-6 py-3 text-white font-bold border-2 border-red-500 bg-black rounded-full">Visit Project</Link>
  </div>
</div>

        </div>
        <div className="relative group" data-Aos="fade-up">
  <img
    className="h-auto max-w-full rounded-lg transition-all duration-300 group-hover:opacity-80"
    src={ste}
    alt=""
  />
  <div className="absolute inset-0 flex items-center justify-center opacity-0 bg-black bg-opacity-80 transition-opacity duration-300 group-hover:opacity-100">
    <Link to='/project/11' className="px-6 py-3 text-white font-bold border-2 border-purple-500 bg-black rounded-full">Visit Project</Link>
  </div>
</div>
    </div>
    <div className="grid gap-4">
        <div>
        <div className="relative group" data-Aos="fade-left">
  <img
    className="h-auto max-w-full rounded-lg transition-all duration-300 group-hover:opacity-80"
    src={qu}
    alt=""
  />
  <div className="absolute inset-0 flex items-center justify-center opacity-0 bg-black bg-opacity-80 transition-opacity duration-300 group-hover:opacity-100">
    <Link to='/project/5' className="px-6 py-3 text-white font-bold border-2 border-purple-500 bg-black rounded-full">Visit Project</Link>
  </div>
</div>

        </div>
        <div>
        <div className="relative group" data-Aos="fade-left">
  <img
    className="h-auto max-w-full rounded-lg transition-all duration-300 group-hover:opacity-80"
    src={st}
    alt=""
  />
  <div className="absolute inset-0 flex items-center justify-center opacity-0 bg-black bg-opacity-80 transition-opacity duration-300 group-hover:opacity-100">
    <Link to='/project/10' className="px-6 py-3 text-white font-bold border-2 border-blue-700 bg-black rounded-full">Visit Project</Link>
  </div>
</div>

        </div>
        <div>
        <div className="relative group" data-Aos="fade-left">
  <img
    className="h-auto max-w-full rounded-lg transition-all duration-300 group-hover:opacity-80"
    src={aut}
    alt=""
  />
  <div className="absolute inset-0 flex items-center justify-center opacity-0 bg-black bg-opacity-80 transition-opacity duration-300 group-hover:opacity-100">
    <Link to='/project/12' className="px-6 py-3 text-white font-bold border-2 border-green-700 bg-black rounded-full">Visit Project</Link>
  </div>
</div>
        </div>
    </div>
</div>
    </div>
  )
}

export default BentoGrid